var popUp = {
	init: function () {

		var $press = $(".modal-press"); 
		var $cheese = $(".modal-cheese")
		// var $popUpWindow = $(".video"); 
		// var $workWindow = $(".work");

		// $popUpWindow.click(function(event) {
		// 	$(this).modal({
		// 		fadeDuration: 250,
		// 		fadeDelay: 0.80
		// 	});
		// 	return false;
		// });

		$press.click(function(event) {
			$(this).modal({
				fadeDuration: 250,
				fadeDelay: 0.80
			});
			return false;
		});

		$cheese.click(function(event) {
			$(this).modal({
				fadeDuration: 250,
				fadeDelay: 0.80
			});
			return false;
		});

		// const myTimeout = setTimeout(function () { $workWindow.modal() }, 10000 );
	}
};

popUp.init();